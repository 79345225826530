/* ==========================================================================
   Design System
   Variables
   ========================================================================== */
/* ==========================================================================
   Design System
   Breakpoint variables.

   NOTE: If any of the values in this file are adjusted,
         they need to be adjusted in vars-breakpoints.js as well.
   ========================================================================== */
/* ==========================================================================
   Design System
   Grid mixins
   ========================================================================== */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-class-pattern */
/* ==========================================================================
   Design System
   Media queries
   ========================================================================== */
/* ==========================================================================
   Design System
   Base styles
   ========================================================================== */
/* ==========================================================================
   consumerfinance.gov
   Page-specific styles for the error pages.
   ========================================================================== */
.m-list--divider {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}
@media only all and (min-width: 37.5625em) {
  .m-list--divider {
    flex-direction: row;
  }
  .m-list--divider .m-list__item + .m-list__item {
    border-left: 1px solid var(--white);
    padding-left: 1.875rem;
  }
}

.error-page {
  background-color: var(--gray-dark);
}
.error-page__main {
  color: var(--white);
}
@media only all and (min-width: 56.3125em) {
  .error-page__main {
    background-blend-mode: multiply;
  }
}
@media only all and (min-width: 63.8125em) {
  .error-page__main {
    background-blend-mode: normal;
  }
}
.error-page__main .a-link--jump {
  color: var(--white);
  border-color: var(--white);
  margin-top: 0.46875em;
}
@media only all and (min-width: 56.3125em) {
  .error-page__404 {
    background: var(--gray-dark) url("/static/img/plug-538x655.png") no-repeat top right;
    background-size: 269px 327px;
  }
}
@media only all and (min-width: 56.3125em) {
  .error-page__500 {
    background: var(--gray-dark) url("/static/img/server-706x619.png") no-repeat center right;
    background-size: 353px 310px;
  }
}